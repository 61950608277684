module.exports = {
  // BE API base url
  API_URL: 'https://alpha-api.rexsoftware.com/v1/global',

  // FE app urls
  REX_APP_URL: 'https://4158-app.rex.pr.rexlabs.io',
  AUTH_APP_URL: 'https://4158-auth-app.rex.pr.rexlabs.io',
  GROUP_APP_URL: 'https://4158-group-app.rex.pr.rexlabs.io',
  GHECK_IN_APP_URL: 'https://4158-check-in-app.rex.pr.rexlabs.io',

  AUTHENTICATION_SERVICE_API_URL: 'https://auth.rexsoftware.com/api/v1',
  AUTHENTICATION_SERVICE_FRONTEND_URL: 'https://auth.rexsoftware.com',

  // Feature flags
  ENABLE_REX_FRAME: true,
  ENABLE_GA_DEBUG: false,
  ENABLE_APPCUES: false,
  ENABLE_NAV_BLOCKING: false,
  ENABLE_SCHEDULED_MESSAGES: true,
  ENABLE_PROSPECTOR: true,

  // Intercom & Zendesk Chat
  INTERCOM_CHAT_ENABLED_UK: true,
  INTERCOM_CHAT_WHITELIST: [],
  ZENDESK_CHAT_WHITELIST: [],

  // Misc
  COOKIE_URL: 'rexlabs.io',
  MAPBOX_TOKEN:
    'pk.eyJ1IjoicmV4c29mdHdhcmUiLCJhIjoiYXloQWNDOCJ9.VLh5gCu-smMd2x8DibPi7g',
  INTERCOM_ID: '',
  SEGMENT_ID: 'lmpTsat8Hqm1dPcfTW0qoeB3cVBHemJq',
  USERLEAP_ID: 'nnsDSgrtfg',
  FLAGSMITH_ID: 'GhHzr2KrxVt3RbmuZuq69z',
  ENVIRONMENT: 'PR',
  AG_GRID_KEY:
    'CompanyName=Rex Software,LicensedApplication=Rex CRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-027746,ExpiryDate=3_May_2023_[v2]_MTY4MzA2ODQwMDAwMA==41b29f2135b58dd313fac291f0944a73',

  RELEASE: {
    STAGE: 'pr',
    VERSION: '0.0.0-pr.4158',
    HASH: '316611c5e2eb181df8e0f24127d3ac4957da2300',
    IMAGE: 'pr-316611c5e2eb181df8e0f24127d3ac4957da2300'
  },

  // REPORT TEMPLATE
  REPORT_TYPEKIT: 'vfu5nuq'
};
